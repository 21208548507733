import { Descriptions } from "antd";
import CenteredSpin from "components/shared/CenteredSpin";
import ErrorResult from "components/shared/ErrorResult";
import dayjs from "dayjs";
import React from "react";
import { descriptionBackgroundColor } from "utils/constants";
import { getDisplayAddress } from "utils/helpers";
import { useAppUser } from "../appUsers/AppUsers.query";

interface Props {
  userToken: string;
}

const KycPersonalData = ({ userToken }: Props) => {
  const { data, error, isFetching, isError } = useAppUser(userToken);

  if (isFetching) {
    return <CenteredSpin />;
  }

  if (isError) {
    return <ErrorResult error={error} />;
  }

  return (
    <Descriptions bordered labelStyle={descriptionBackgroundColor} column={1}>
      <Descriptions.Item label="First name">
        {data?.firstName}
      </Descriptions.Item>
      <Descriptions.Item label="Last name">{data?.lastName}</Descriptions.Item>
      <Descriptions.Item label="Date of Birth">
        {dayjs(data?.dateOfBirth).format("YYYY/MM/DD")}
      </Descriptions.Item>
      <Descriptions.Item label="Phonenumber">
        {data?.phoneNumber}
      </Descriptions.Item>
      <Descriptions.Item label="Email">{data?.emailAddress}</Descriptions.Item>
      <Descriptions.Item label="Home Address">
        {getDisplayAddress(data?.homeAddress)}
      </Descriptions.Item>
      <Descriptions.Item label="Postal Address">
        {getDisplayAddress(data?.postalAddress)}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default KycPersonalData;
