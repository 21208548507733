import { LoginOutlined } from "@ant-design/icons";
import { Layout, Result, Space } from "antd";
import AppUser from "components/pages/appUsers/AppUserDetails";
import AppUsers from "components/pages/appUsers/AppUsers";
import Errors from "components/pages/errors/Errors";
import Invite from "components/pages/invite/Invite";
import { useMe } from "components/pages/invite/Invite.query";
import KycDetails from "components/pages/kyc/KycDetails";
import UserAdministration from "components/pages/userAdministration/UserAdministration";
import { AnimatePresence } from "framer-motion";
import { Switch, useHistory, useLocation } from "react-router-dom";
import Home from "../pages/home/Home";
import Kyc from "../pages/kyc/Kyc";
import AnimatedRoute from "../shared/AnimatedRoute";
import MenuSider from "./MenuSider";
import UserNotifications from "./UserNotifications";

const { Header, Content } = Layout;

const MainLayout = () => {
  const location = useLocation();
  const history = useHistory();
  const { data } = useMe();

  const goToLoginScreen = () => {
    history.push("/login");
  };

  return (
    <Layout className="min-h-screen bg-transparent">
      <MenuSider />
      <Layout className="bg-black/75 random-image-background">
        <Header className="bg-gradient-to-r from-ant-grey to-visa-blue flex flex-row justify-end">
          <Space size={20}>
            <span>
              Hello <span className="text-pink-300">{data?.email}</span>!
            </span>
            <UserNotifications />
            <LoginOutlined
              className="text-xl mt-[1.7rem]"
              onClick={goToLoginScreen}
            />
          </Space>
        </Header>
        <Content className="mt-5 mx-3">
          <div className="container mx-auto">
            <AnimatePresence exitBeforeEnter initial={false}>
              {/* location and key are necessary in detecting change for AnimatePresence */}
              <Switch location={location} key={location.pathname}>
                <AnimatedRoute exact path="/">
                  <Home />
                </AnimatedRoute>
                <AnimatedRoute path="/kyc" protected exact>
                  <Kyc />
                </AnimatedRoute>
                <AnimatedRoute path="/kyc/:userToken" protected>
                  <KycDetails />
                </AnimatedRoute>
                <AnimatedRoute path="/invite" protected>
                  <Invite />
                </AnimatedRoute>
                <AnimatedRoute path="/errors" protected>
                  <Errors />
                </AnimatedRoute>
                <AnimatedRoute path="/adminUsers" protected>
                  <UserAdministration />
                </AnimatedRoute>
                <AnimatedRoute path="/appUsers" protected exact>
                  <AppUsers />
                </AnimatedRoute>
                <AnimatedRoute path="/appUsers/:userToken" protected>
                  <AppUser />
                </AnimatedRoute>
                <AnimatedRoute path="*">
                  <Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the page you visited does not exist."
                  />
                </AnimatedRoute>
              </Switch>
            </AnimatePresence>
          </div>
        </Content>
        <div className="text-center p-6">
          Visa Admin ©2021 Created by{" "}
          <a
            href="https://peakfs.io/"
            target="_blank"
            rel="noreferrer"
            className="text-blue-300"
          >
            Peak Financial Services
          </a>
        </div>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
