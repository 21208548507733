import { buildURLQuery } from "utils/helpers";
import { axiosInstance } from "./axiosInstance";

export type KycStatus = "pending" | "approved" | "declined" | "suspended";
export type DDStatus = "SDD" | "FDD" | "EDD" | "SFDD";
export type AgeGroup = "below14" | "between14and18" | "above18";

export interface KycFourEyes {
  approvers: string[];
  status: KycStatus;
  userToken: string;
  createdAt: Date;
  updatedAt: Date;
}
export interface KycUser {
  userToken: string;
  partnerBankId: string;
  status: KycStatus;
  natIdFront?: string;
  natIdBack?: string;
  passport?: string;
  driving?: string;
  selfie?: string;
  studentIdFront?: string;
  studentIdBack?: string;
  address?: string;
  pep?: boolean;
  supervisorToken?: string;
  ageGroup?: AgeGroup;
  ddStatus?: DDStatus;
  fourEyes?: KycFourEyes;
  createdAt: string;
  updatedAt: string;
}
export interface ChangeStatusQuery {
  userToken: string;
  status: "approve" | "suspend" | "decline";
}

export interface KycUserQuery {
  page: number;
  pageSize: number;
  status?: KycStatus;
  userToken?: string;
}

export const getKycUsers = async (q: KycUserQuery): Promise<KycUser[]> => {
  return (await axiosInstance.get(`/admin/v1/kyc?${buildURLQuery(q)}`)).data;
};

export const getKycUser = async (id: string): Promise<KycUser> =>
  (await axiosInstance.get(`/admin/v1/kyc/${id}`)).data;

export const changeStatus = async ({ status, userToken }: ChangeStatusQuery) =>
  axiosInstance.post(`/admin/v1/kyc/${status}`, {
    userToken,
  });

export const deleteFourEyes = async (userToken: string) =>
  axiosInstance.delete(`/admin/v1/kyc/fourEyes/${userToken}`);
