import { axiosInstance } from "./axiosInstance";

export interface LoginRequestBody {
  email: string;
  password: string;
}

export interface RegisterRequestBody {
  email: string;
  password: string;
  partnerBankId: string;
  uid: string;
}

export interface ValidateEmailCodeRequestBody {
  email: string;
  emailCode: string;
}

export interface AdminUser {
  email: string;
  partnerBankId: string | null;
  superUser?: boolean;
  roles?: string[];
}

export interface Invite {
  email: string;
  partnerBankId: string;
}

// Backend API methods

export const login = async (params: LoginRequestBody): Promise<any> =>
  axiosInstance.post(
    "/admin/v1/auth/login",
    { ...params },
    { withCredentials: false }
  );

export const validateEmailCode = async (
  params: ValidateEmailCodeRequestBody
): Promise<any> =>
  axiosInstance.post("/admin/v1/auth/validateEmailCode", { ...params });

export const registerAdmin = async (
  params: RegisterRequestBody
): Promise<any> => axiosInstance.post("/admin/v1/auth/register", { ...params });

export const sendInvite = async (params: AdminUser): Promise<any> =>
  axiosInstance.post("/admin/v1/auth/sendInvite", { ...params });

export const me = async (): Promise<AdminUser> =>
  (await axiosInstance.get("/admin/v1/auth/me")).data;

export const findInvite = async (uid: string): Promise<Invite> =>
  (await axiosInstance.get(`/admin/v1/auth/invite/${uid}`)).data;
