import { Col, Descriptions, Divider, Row } from "antd";
import CenteredSpin from "components/shared/CenteredSpin";
import ErrorResult from "components/shared/ErrorResult";
import PageTitle from "components/shared/PageTitle";
import dayjs from "dayjs";
import React from "react";
import { useParams } from "react-router-dom";
import { descriptionBackgroundColor } from "utils/constants";
import { getDisplayAddress } from "utils/helpers";
import AppUserBalance from "./AppUserBalance";
import AppUserCards from "./AppUserCards";
import AppUserRels from "./AppUserRels";
import { useAppUser } from "./AppUsers.query";
import AppUserSavings from "./AppUserSavings";
import AppUserTransactions from "./AppUserTransactions";

interface Props {}

const AppUser = (props: Props) => {
  const { userToken } = useParams<{ userToken: string }>();
  const { data, error, isFetching, isError } = useAppUser(userToken);

  if (isFetching) {
    return <CenteredSpin />;
  }

  if (isError) {
    return <ErrorResult error={error} />;
  }

  return (
    <>
      <PageTitle text="App User" />
      <Descriptions
        bordered
        title="Personal Details"
        labelStyle={descriptionBackgroundColor}
        size="middle"
        column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 3 }}
      >
        <Descriptions.Item label="First name">
          {data?.firstName}
        </Descriptions.Item>
        <Descriptions.Item label="Last name">
          {data?.lastName}
        </Descriptions.Item>
        <Descriptions.Item label="Date of Birth">
          {dayjs(data?.dateOfBirth).format("YYYY/MM/DD")}
        </Descriptions.Item>
        <Descriptions.Item label="Phonenumber">
          {data?.phoneNumber}
        </Descriptions.Item>
        <Descriptions.Item label="Email">
          {data?.emailAddress}
        </Descriptions.Item>
        <Descriptions.Item label="Home Address">
          {getDisplayAddress(data?.homeAddress)}
        </Descriptions.Item>
        <Descriptions.Item label="Postal Address">
          {getDisplayAddress(data?.postalAddress)}
        </Descriptions.Item>
        <Descriptions.Item label="Created at">
          {data?.createdAt}
        </Descriptions.Item>
        <Descriptions.Item label="Updated at">
          {data?.updatedAt}
        </Descriptions.Item>
      </Descriptions>
      <AppUserRels userToken={userToken} />
      <Row gutter={16}>
        <Col xl={12} xs={24}>
          <div>
            <Divider orientation="left">Balance</Divider>
            <AppUserBalance userToken={userToken} />
          </div>
          <div>
            <Divider orientation="left">Saving subpockets</Divider>
            <AppUserSavings userToken={userToken} />
          </div>
          <div>
            <Divider orientation="left">Cards</Divider>
            <AppUserCards
              userToken={userToken}
              firstName={data!.firstName}
              lastName={data!.lastName}
            />
          </div>
        </Col>
        <Col xl={12} xs={24}>
          <Divider orientation="left">Transactions</Divider>
          <AppUserTransactions userToken={userToken} />
        </Col>
      </Row>
    </>
  );
};

export default AppUser;
