import { LoadingOutlined } from "@ant-design/icons";
import { notification } from "antd";
import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import { displayErrorMessage } from "utils/messageHelper";
import { v4 as uuidv4 } from "uuid";
import App from "./App";
import "./styles/output.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
    mutations: {
      onError: displayErrorMessage,
      onMutate: () => {
        const key = uuidv4();
        const func = setTimeout(() => {
          notification.open({
            key,
            message: "Long running operation",
            description: "This operation may take a while to complete.",
            type: "info",
            duration: 0,
            placement: "topLeft",
            icon: <LoadingOutlined />,
          });
        }, 500);

        return { key, func };
      },
      onSettled: (data, error, variables, context) => {
        const ctx = context as { key?: string; func?: NodeJS.Timeout };
        if (ctx.func) clearTimeout(ctx.func);
        if (ctx.key) notification.close(ctx.key);
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        <App />
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
