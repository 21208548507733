import { Card } from "apis/appUsers.api";
import BooleanDisplay from "components/shared/BooleanDisplay";
import ServerStateDisplay from "components/shared/ServerStateDisplay";
import React from "react";
import { useAppUserCards } from "./AppUsers.query";
import cardBg from "./cardBackground.png";
import logo from "./visaLogo.svg";

const AppUserCard = ({
  card,
  firstName,
  lastName,
}: {
  card: Card;
  firstName: string;
  lastName: string;
}) => {
  return (
    <div className="w-[20rem] h-[12rem] m-auto bg-red-100 rounded-xl relative text-white shadow-2xl transition-transform transform hover:scale-105">
      <img
        className="relative object-cover w-full h-full rounded-xl"
        src={cardBg}
        alt="card cover"
      />

      <div className="w-full px-6 absolute top-4">
        <div className="flex justify-between">
          <div className="">
            <p className="font-light">Name</p>
            <p className="font-medium tracking-wide">
              {firstName + " " + lastName}
            </p>
          </div>
          <img className="w-14 h-14" src={logo} alt="visa logo" />
        </div>
        <div className="pt-1">
          <p className="font-light">PSP Public Token</p>
          <p className="font-medium">{card.pspPublicToken}</p>
        </div>
        <div className="pt-4 pr-6">
          <div className="flex justify-between font-light">
            <div>
              <p>Active</p>
              <BooleanDisplay state={card.active} />
            </div>
            <div>
              <p>Issued</p>
              <BooleanDisplay state={card.issued} />
            </div>
            <div>
              <p>Not Blocked</p>
              <BooleanDisplay state={!card.blocked} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AppUserCards = ({
  data,
  firstName,
  lastName,
}: {
  data: Card[];
  firstName: string;
  lastName: string;
}) => {
  return (
    <div className="flex flex-row flex-wrap space-y-2 justify-around">
      {data.map((card) => (
        <AppUserCard card={card} firstName={firstName} lastName={lastName} />
      ))}
    </div>
  );
};

export default ServerStateDisplay<
  Card[],
  { firstName: string; lastName: string }
>(AppUserCards, (userToken: string) => useAppUserCards(userToken));
