import { message } from "antd";
import {
  changeStatus,
  ChangeStatusQuery,
  deleteFourEyes,
  getKycUser,
  getKycUsers, KycUser,
  KycUserQuery
} from "apis/kyc.api";
import { AxiosError } from "axios";
import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useList } from "react-use";
import { ServerState } from "utils/serverState";

export const useKycUsers = (q: KycUserQuery) =>
  useQuery<KycUser[], AxiosError>(ServerState.KYC_USERS, () => getKycUsers(q));

export const useKycUser = (id: string) => {
  const user = useQuery<KycUser, AxiosError>([ServerState.KYC_USER, id], () =>
    getKycUser(id)
  );

  const [images, { push, clear }] = useList<string>([]);
  useEffect(() => {
    const addToImagesIfExists = (prop?: string) => {
      if (prop) push(prop);
    };
    clear();
    addToImagesIfExists(user.data?.natIdFront);
    addToImagesIfExists(user.data?.natIdBack);
    addToImagesIfExists(user.data?.studentIdFront);
    addToImagesIfExists(user.data?.studentIdBack);
    addToImagesIfExists(user.data?.passport);
    addToImagesIfExists(user.data?.driving);
    addToImagesIfExists(user.data?.address);
    addToImagesIfExists(user.data?.selfie);
  }, [clear, user.data, push]);

  return {
    ...user,
    images,
  };
};

export const useChangeStatus = () => {
  const queryClient = useQueryClient();

  return useMutation((param: ChangeStatusQuery) => changeStatus(param), {
    onSuccess: (resp, vars) => {
      queryClient.invalidateQueries([ServerState.KYC_USER, vars.userToken]);
      message.success("KYC status progressed");
    },
  });
};

export const useDeleteFourEyes = () => {
  const queryClient = useQueryClient();

  return useMutation((userToken: string) => deleteFourEyes(userToken), {
    onSuccess: (resp, vars) => {
      queryClient.invalidateQueries([ServerState.KYC_USER, vars]);
      message.success("Four eye principle restarted");
    },
  });
};
