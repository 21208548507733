import { RedoOutlined } from "@ant-design/icons";
import { Button, Carousel, Col, Divider, Row } from "antd";
import { useDeleteFourEyes, useKycUser } from "components/pages/kyc/Kyc.query";
import AreYouSure from "components/shared/AreYouSure";
import CenteredSpin from "components/shared/CenteredSpin";
import ErrorResult from "components/shared/ErrorResult";
import PageTitle from "components/shared/PageTitle";
import React from "react";
import { useParams } from "react-router-dom";
import { resolveImageUrl } from "utils/kycImageUrlResolver";
import KycApproverSteps from "./KycApproverSteps";
import KycData from "./KycData";
import KycPersonalData from "./KycPersonalData";
import KycStatusChangeButtonGroup from "./KycStatusChangeButtonGroup";

interface Props {}

const KycDetails = (props: Props) => {
  const { userToken } = useParams<{ userToken: string }>();
  const { isFetching, isError, data, images, error } = useKycUser(userToken);
  const { mutate } = useDeleteFourEyes();

  if (isFetching) {
    return <CenteredSpin />;
  }

  if (isError) {
    return <ErrorResult error={error} />;
  }

  return (
    <>
      <PageTitle text="KYC Details" />
      <div className="flex justify-between mb-2 space-x-20">
        <div className="flex-grow flex flex-row space-x-5 items-center bg-visa-blue/20 p-3 border-gray-800 border">
          <KycApproverSteps fourEyes={data!.fourEyes} />
          {data?.status === "pending" && data?.fourEyes && (
            <AreYouSure onConfirm={() => mutate(userToken)}>
              <Button
                type="primary"
                danger
                size="middle"
                icon={<RedoOutlined />}
              >
                Restart four eye principle
              </Button>
            </AreYouSure>
          )}
        </div>
        {data?.status === "pending" && (
          <KycStatusChangeButtonGroup data={data} />
        )}
      </div>
      <Row gutter={16}>
        <Col xl={8} xs={24}>
          <Divider orientation="left">Personal data</Divider>
          <KycPersonalData userToken={data!.userToken} />
          <Divider orientation="left">KYC data</Divider>
          <KycData data={data!} />
        </Col>
        <Col xl={16} xs={24}>
          <Divider orientation="left">KYC images</Divider>
          <Carousel
            adaptiveHeight
            centerMode
            dotPosition="top"
            draggable
            arrows
            autoplay
          >
            {images.map((img) => (
              <img
                alt="kyc document"
                src={resolveImageUrl(img)}
                key={img}
                className="min-w-full object-contain h-full"
              />
            ))}
          </Carousel>
        </Col>
      </Row>
    </>
  );
};

export default KycDetails;
