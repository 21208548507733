import { Balance } from "apis/appUsers.api";
import ServerStateDisplay from "components/shared/ServerStateDisplay";
import React from "react";
import { balanceFormat } from "utils/helpers";
import { useAppUserBalance } from "./AppUsers.query";
// import wallet from "./wallet.svg";

interface Props {
  data: Balance;
}

const BalanceText = (props: { text: string }) => (
  <p className="font-light text-gray-300">{props.text}</p>
);
const BalanceValue = (props: { value: number }) => (
  <p className="font-semibold text-3xl tracking-wide">
    <span className="text-gray-600">$ </span>
    {balanceFormat(props.value)}
  </p>
);

const AppUserBalance = ({ data }: Props) => {
  return (
    <div className="relative backdrop-brightness-200  hover:ring-1 rounded-xl p-4 w-full min-h-[8rem] flex flex-row justify-evenly items-center flex-wrap gap-6">
      {/* <img
        className="absolute right-4 bottom-4 h-[5rem] rounded-xl -z-10 brightness-50"
        src={wallet}
        alt="balance cover"
      /> */}
      <div>
        <BalanceText text="Main account balance" />
        <BalanceValue value={data.pspMainAccountBalance} />
      </div>
      {data.pspSavingsBalance && data.pspSavingsBalance > 0 ? (
        <div className="mt-4">
          <BalanceText text="Savings account balance" />
          <BalanceValue value={data.pspSavingsBalance} />
        </div>
      ) : (
        ""
      )}
      {data.savingsSubpocketsBalanceSum &&
      data.savingsSubpocketsBalanceSum > 0 ? (
        <div className="mt-4">
          <BalanceText text="Subpockets balance sum" />
          <BalanceValue value={data.savingsSubpocketsBalanceSum} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ServerStateDisplay<Balance>(AppUserBalance, (userToken) =>
  useAppUserBalance(userToken)
);
