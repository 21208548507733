import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_DOMAIN,
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (
      err?.response?.status === 401 &&
      !originalConfig._retry
    ) {
      originalConfig._retry = true;
      try {
        await axiosInstance.get("/admin/v1/auth/token");
        return axiosInstance(originalConfig);
      } catch (_error) {
        return Promise.reject(err);
      }
    }

    return Promise.reject(err);
  }
);
