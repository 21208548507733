import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import React from "react";

interface Props {
  state: boolean;
}

const BooleanDisplay = ({ state }: Props) => {
  return (
    <>
      {state ? (
        <CheckCircleTwoTone twoToneColor="green" />
      ) : (
        <CloseCircleTwoTone twoToneColor="red" />
      )}
    </>
  );
};

export default BooleanDisplay;
