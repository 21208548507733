import { Timeline } from "antd";
import { Transaction } from "apis/appUsers.api";
import ServerStateDisplay from "components/shared/ServerStateDisplay";
import React from "react";
import { useAppUserTransactions } from "./AppUsers.query";

interface Props {
  data: Transaction[];
}

const AppUserTransactions = ({ data }: Props) => {
  return (
    <Timeline mode="alternate">
      {data.map((t) => {
        if (t.trnAmount < 0) {
          return (
            <Timeline.Item color="red" position="right" key={t.id}>
              <span className="text-2xl text-red-500">
                {t.trnAmount} {t.trnCurrencyCode}
              </span>{" "}
              <span className="font-light text-gray-500">
                {t.eventDate.toISOString()}
              </span>
              <br />
              <span className="font-light text-gray-500">{t.fullName}</span>
            </Timeline.Item>
          );
        }
        return (
          <Timeline.Item color="green" position="left" key={t.id}>
            <span className="text-2xl text-green-500">
              {t.trnAmount} {t.trnCurrencyCode}
            </span>{" "}
            <span className="font-light text-gray-500">
              {t.eventDate.toISOString()}
            </span>
            <br />
            <span className="font-light text-gray-500">{t.fullName}</span>
          </Timeline.Item>
        );
      })}
    </Timeline>
  );
};

export default ServerStateDisplay<Transaction[]>(
  AppUserTransactions,
  (userToken) => useAppUserTransactions(userToken)
);
