import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";
import { Badge, Descriptions } from "antd";
import { KycStatus, KycUser } from "apis/kyc.api";
import React from "react";
import { Link } from "react-router-dom";
import { descriptionBackgroundColor } from "utils/constants";
import { ageGroupToLabel } from "utils/helpers";

interface Props {
  data: KycUser;
}

const KycData = ({ data }: Props) => {
  const convertStatusToBadgeStatus = (
    status?: KycStatus
  ): "error" | "success" | "warning" | "processing" | "default" => {
    switch (status) {
      case "approved":
        return "success";
      case "declined":
        return "error";
      case "pending":
        return "processing";
      case "suspended":
        return "warning";
      default:
        return "default";
    }
  };

  return (
    <Descriptions bordered labelStyle={descriptionBackgroundColor} column={1}>
      <Descriptions.Item label="User Token">
        {data?.userToken}
      </Descriptions.Item>
      {data?.supervisorToken && (
        <Descriptions.Item label="Supervisor Token">
          <Link to={`/kyc/${data?.supervisorToken}`}>
            {data?.supervisorToken}
          </Link>
        </Descriptions.Item>
      )}
      <Descriptions.Item label="Status">
        <Badge
          status={convertStatusToBadgeStatus(data?.status)}
          text={data?.status}
        />
      </Descriptions.Item>
      <Descriptions.Item label="DD Status">{data?.ddStatus}</Descriptions.Item>
      <Descriptions.Item label="Age Group">
        {ageGroupToLabel(data?.ageGroup)}
      </Descriptions.Item>
      <Descriptions.Item label="Created at">
        {data?.createdAt}
      </Descriptions.Item>
      <Descriptions.Item label="Updated at">
        {data?.updatedAt}
      </Descriptions.Item>
      <Descriptions.Item label="PEP">
        {data?.pep ? (
          <ExclamationCircleTwoTone twoToneColor="#F00" className="text-xl m-0" />
        ) : (
          <CheckCircleTwoTone twoToneColor="#0F0" className="text-xl m-0" />
        )}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default KycData;
