import { Steps } from "antd";
import { KycFourEyes } from "apis/kyc.api";
import React from "react";

const { Step } = Steps;

const KycApproverSteps = ({ fourEyes }: { fourEyes?: KycFourEyes }) => {
  const approvers = fourEyes?.approvers?.length || 0;
  const firstApprover = approvers > 0 ? fourEyes?.approvers[0] : "Unknown yet";
  const firstDesc = approvers > 0 ? `Set KYC to ${fourEyes?.status}` : "";
  const secondApprover = approvers > 1 ? fourEyes?.approvers[1] : "Unknown yet";
  const secondDesc = approvers > 1 ? `Set KYC to ${fourEyes?.status}` : "";
  return (
    <Steps current={approvers} className="w-full" status="finish">
      <Step title="Four eye principle started" description="KYC is pending" />
      <Step title={firstApprover} description={firstDesc} />
      <Step title={secondApprover} description={secondDesc} />
    </Steps>
  );
};

export default KycApproverSteps;
