import {
  AppUser,
  AppUserListItem,
  AppUserRelations,
  AppUsersQuery,
  Balance,
  Card,
  getAppUser,
  getAppUserBalance,
  getAppUserCards,
  getAppUserRelations,
  getAppUsers,
  getAppUserTransactions,
  Transaction,
} from "apis/appUsers.api";
import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { ServerState } from "utils/serverState";

export const useAppUsers = (query: AppUsersQuery) =>
  useQuery<AppUserListItem[], AxiosError>(
    [ServerState.APP_USERS, { ...query }],
    () => getAppUsers(query)
  );

export const useAppUser = (userToken: string) =>
  useQuery<AppUser, AxiosError>([ServerState.APP_USER, userToken], () =>
    getAppUser(userToken)
  );

export const useAppUserCards = (userToken: string) =>
  useQuery<Card[], AxiosError>([ServerState.APP_USER_CARDS, userToken], () =>
    getAppUserCards(userToken)
  );

export const useAppUserBalance = (userToken: string) =>
  useQuery<Balance, AxiosError>([ServerState.APP_USER_BALANCE, userToken], () =>
    getAppUserBalance(userToken)
  );

export const useAppUserRelations = (userToken: string) =>
  useQuery<AppUserRelations, AxiosError>([ServerState.APP_USER_RELATIONS, userToken], () =>
    getAppUserRelations(userToken)
  );

export const useAppUserTransactions = (userToken: string) =>
  useQuery<Transaction[], AxiosError>([ServerState.APP_USER_TRANSACTIONS, userToken], () =>
    getAppUserTransactions(userToken)
  );
