import { AdminUser } from "./auth.api";
import { axiosInstance } from "./axiosInstance";

export interface AdminNotification {
  _id: string;
  email: string;
  read: boolean;
  link: string;
  description: string;
  category: "KYC" | "other";
  createdAt: Date;
}

export const getUsers = async (): Promise<AdminUser[]> => {
  return (await axiosInstance.get("/admin/v1/users")).data;
};

export const getRoles = async () => {
  return (await axiosInstance.get("/admin/v1/users/roles")).data;
};

export const saveRoles = async (props: { email: string; roles: string[] }) =>
  axiosInstance.put("/admin/v1/users/roles", props);

export const deleteUser = async (email: string): Promise<void> =>
  axiosInstance.delete(`/admin/v1/users/${email}`);

export const markAllAsRead = async (): Promise<void> =>
  axiosInstance.put(`/admin/v1/notifications/read`);

export const markAsRead = async (id: string): Promise<void> =>
  axiosInstance.put(`/admin/v1/notifications/read/${id}`);

export const getUnreadNotifications = async (): Promise<AdminNotification[]> =>
  (await axiosInstance.get(`/admin/v1/notifications`)).data;
