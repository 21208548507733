import { Button, Form, Input, message } from "antd";
import { AdminUser, sendInvite } from "apis/auth.api";
import CenteredSpin from "components/shared/CenteredSpin";
import ErrorResult from "components/shared/ErrorResult";
import PageTitle from "components/shared/PageTitle";
import React from "react";
import { displayErrorMessage } from "utils/messageHelper";
import { useMe } from "./Invite.query";

interface Props {}

const Invite = (props: Props) => {
  const { data, isError, error, isFetching } = useMe();

  if (isError) {
    return <ErrorResult error={error} />;
  }

  if (isFetching) {
    return <CenteredSpin />;
  }

  const onFinish = (values: AdminUser) => {
    sendInvite(values)
      .then(() => message.success("Invite sent successfully"))
      .catch(displayErrorMessage);
  };

  return (
    <>
      <PageTitle text="Send invitation" />
      <div className="flex lg:flex-row flex-col space-x-4">
        <Form
          initialValues={{ partnerBankId: data?.partnerBankId }}
          onFinish={onFinish}
          labelCol={{ span: 8 }}
          className="flex-1"
        >
          <Form.Item
            label="Email address"
            name="email"
            rules={[{ required: true, message: "Please input email address!" }]}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item label="Partner Bank ID" name="partnerBankId">
            <Input disabled={!data?.superUser} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Invite
            </Button>
          </Form.Item>
        </Form>
        <div className="flex-1 text-lg bg-gray-800 m-auto px-16 py-8 shadow-md">
          <div>
            You can invite your team members to your organization (identified by{" "}
            <span className="text-red-300">Partner Bank ID</span>). <br />
            They'll get an invitation mail with a link to our registration
            screen, where they have to provide a password. <br />
            An invitation is valid for{" "}
            <span className="text-red-300">7 days</span> and can be used only
            once.
          </div>
        </div>
      </div>
    </>
  );
};

export default Invite;
