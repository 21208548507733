import { Avatar } from "antd";
import { AppUserRelations } from "apis/appUsers.api";
import ServerStateDisplay from "components/shared/ServerStateDisplay";
import React from "react";
import { Link } from "react-router-dom";
import { useAppUserRelations } from "./AppUsers.query";

interface Props {
  data: AppUserRelations;
}

const TokenDisplay = (props: { userToken: string; parent: boolean }) => {
  return (
    <>
      <Link to={`/appUsers/${props.userToken}`} className="">
        <Avatar className="mr-2">{props.parent ? "C" : "P"}</Avatar>
        {props.userToken}
      </Link>
    </>
  );
};

const AppUserRels = ({ data }: Props) => {
  const renderData = data.isParent ? data.children : data.parents;
  return (
    <section className="p-4">
      <div className="font-bold mb-2">
        {data.isParent ? "Children:" : "Parents:"}
      </div>
      <div className="flex flex-row flex-wrap gap-4">
        {renderData?.map((token) => (
          <TokenDisplay userToken={token} parent={data.isParent} />
        ))}
      </div>
    </section>
  );
};

export default ServerStateDisplay<AppUserRelations>(AppUserRels, (userToken) =>
  useAppUserRelations(userToken)
);
