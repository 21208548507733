import { Result } from "antd";
import { AxiosError } from "axios";

export default function ErrorResult({
  error,
  title = "Something went wrong",
}: {
  error: any;
  title?: string;
}) {
  let status = error.response?.status;
  if (!["403", "404", "500"].includes(status)) status = "error";
  return (
    <Result
      status={status}
      title={title}
      subTitle={error.response?.data?.message ?? (error as AxiosError).message}
    />
  );
}
