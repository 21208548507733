import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import { Input, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { KycStatus, KycUser, KycUserQuery } from "apis/kyc.api";
import { useKycUsers } from "components/pages/kyc/Kyc.query";
import ErrorResult from "components/shared/ErrorResult";
import PageTitle from "components/shared/PageTitle";
import dayjs from "dayjs";
import useSearchParams from "hooks/useSearchParams";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ageGroupToLabel } from "utils/helpers";

interface Props {}

const DEFAULT_PAGE_SIZE = 10;

const Kyc = (props: Props) => {
  const status = useSearchParams().get("status") as KycStatus;
  const history = useHistory();
  const [query, setQuery] = useState<KycUserQuery>({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    status,
  });
  const paginationChanged = (page: number, pageSize?: number) =>
    setQuery((q) => ({ ...q, page, pageSize: pageSize ?? DEFAULT_PAGE_SIZE }));
  const { data, error, isError, isFetching, refetch } = useKycUsers(query);

  useEffect(() => {
    setQuery((q) => ({ ...q, status }));
  }, [status, setQuery]);
  useEffect(() => {
    console.log(query);
    refetch();
  }, [refetch, query]);

  const columns: ColumnsType<KycUser> = [
    {
      title: "User Token",
      dataIndex: "userToken",
      sorter: (a: KycUser, b: KycUser) =>
        a.userToken.localeCompare(b.userToken),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterDropdown: () => (
        <Input
          placeholder={`Search by User Token`}
          onChange={(e) =>
            setQuery((q) => ({ ...q, userToken: e.target.value }))
          }
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a: KycUser, b: KycUser) => a.status.localeCompare(b.status),
    },
    {
      title: "PEP",
      dataIndex: "pep",
      render: (value: any, record: KycUser, index: number) => {
        return value ? (
          <ExclamationCircleTwoTone twoToneColor="#F00" />
        ) : (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        );
      },
    },
    {
      title: "Supervisor Token",
      dataIndex: "supervisorToken",
      sorter: (a: KycUser, b: KycUser) =>
        (a.supervisorToken || "").localeCompare(b.supervisorToken || ""),
    },
    {
      title: "Age Group",
      dataIndex: "ageGroup",
      render: (value: any) => ageGroupToLabel(value),
    },
    {
      title: "DD Status",
      dataIndex: "ddStatus",
      sorter: (a: KycUser, b: KycUser) =>
        (a.ddStatus || "").localeCompare(b.ddStatus || ""),
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      sorter: (a: KycUser, b: KycUser) => dayjs(a.createdAt).diff(b.createdAt),
      defaultSortOrder: "descend",
    },
    {
      title: "Updated at",
      dataIndex: "updatedAt",
      sorter: (a: KycUser, b: KycUser) => dayjs(a.updatedAt).diff(b.updatedAt),
    },
  ];

  if (isError) {
    return <ErrorResult error={error} />;
  }

  return (
    <>
      <PageTitle text="List of KYC objects" />
      <Table
        columns={columns}
        dataSource={data}
        loading={isFetching}
        rowKey={(record) => record.userToken}
        pagination={{
          defaultPageSize: DEFAULT_PAGE_SIZE,
          onChange: paginationChanged,
          showSizeChanger: true,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              history.push(`/kyc/${record.userToken}`);
            },
          };
        }}
      />
    </>
  );
};

export default Kyc;
