import { PageHeader } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

interface Props {
  text: string;
  subTitle?: string;
}

const PageTitle = (props: Props) => {
  const history = useHistory();
  return (
    <PageHeader
      onBack={() => history.goBack()}
      title={props.text}
      subTitle={props.subTitle}
    />
  );
};

export default PageTitle;
