import { AxiosError } from "axios";
import React from "react";
import { UseQueryResult } from "react-query";
import CenteredSpin from "./CenteredSpin";
import ErrorResult from "./ErrorResult";

type PropsWithData<K, T> = {
  data: K;
} & T;

type PropsWithUserToken<T extends Object> = {
  userToken: string;
} & T;

const ServerStateDisplay =
  <K, T extends Object = {}>(
    Component: React.FC<PropsWithData<K, T>>,
    query: (userToken: string) => UseQueryResult<K, AxiosError<any>>
  ) =>
  (props: PropsWithUserToken<T>) => {
    const { data, isError, error, isFetching } = query(props.userToken);
    if (isFetching) {
      return <CenteredSpin />;
    }
    if (isError) {
      return <ErrorResult error={error} />;
    }
    return <Component data={data!} {...props} />;
  };

export default ServerStateDisplay;
