import { Table } from "antd";
import CenteredSpin from "components/shared/CenteredSpin";
import ErrorResult from "components/shared/ErrorResult";
import PageTitle from "components/shared/PageTitle";
import React from "react";
import { inspect } from "util";
import { useErrors } from "./Errors.query";
import SyntaxHighlighter from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { VisaBackendError } from "apis/errors.api";
import dayjs from "dayjs";

interface Props {}

const columns = [
  {
    title: "Error name",
    dataIndex: "errorName",
    sorter: (a: VisaBackendError, b: VisaBackendError) =>
      a.errorName.localeCompare(b.errorName),
  },
  {
    title: "Timestamp",
    dataIndex: "timestamp",
    sorter: (a: VisaBackendError, b: VisaBackendError) =>
      dayjs(a.timestamp).diff(b.timestamp),
  },
  {
    title: "Error data",
    dataIndex: "errorData",
    render: (value: any, record: any, index: number) => (
      <SyntaxHighlighter language="json" style={darcula}>
        {JSON.stringify(value)}
      </SyntaxHighlighter>
    ),
  },
];

const Errors = (props: Props) => {
  const { data, error, isError, isFetching } = useErrors();

  if (isError) {
    return <ErrorResult error={error} />;
  }

  return (
    <>
      <PageTitle text="Errors in Visa Unified Backend" />
      <Table
        columns={columns}
        dataSource={data}
        loading={isFetching}
        pagination={{ pageSize: 20 }}
      />
    </>
  );
};

export default Errors;
