import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table, Tooltip } from "antd";
import { ColumnsType, ColumnType } from "antd/lib/table";
import { FilterConfirmProps } from "antd/lib/table/interface";
import { AppUserListItem, AppUsersQuery } from "apis/appUsers.api";
import ErrorResult from "components/shared/ErrorResult";
import PageTitle from "components/shared/PageTitle";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppUsers } from "./AppUsers.query";

interface Props {}

const DEFAULT_PAGE_SIZE = 10;

const AppUsers = (props: Props) => {
  const history = useHistory();
  const [query, setQuery] = useState<AppUsersQuery>({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const paginationChanged = (page: number, pageSize?: number) =>
    setQuery((q) => ({ ...q, page, pageSize: pageSize ?? DEFAULT_PAGE_SIZE }));
  const { isError, error, isFetching, data } = useAppUsers(query);

  const handleSearch = (
    selectedKeys: React.Key[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: string
  ) => {
    confirm({ closeDropdown: false });
    setQuery({
      ...query,
      [dataIndex]: selectedKeys[0] ?? "",
    });
  };

  const columnSearchProps = (
    dataIndex: keyof AppUserListItem,
    title: string
  ): Partial<ColumnType<AppUserListItem>> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <Tooltip
        title="Because of field level data encryption, only exact match works on filtering"
        color="orange"
      >
        <Space className="p-2">
          <Input
            placeholder={`Search ${title}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="middle"
          >
            Search
          </Button>
        </Space>
      </Tooltip>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : false,
  });

  const columns: ColumnsType<AppUserListItem> = [
    {
      title: "Usertoken",
      dataIndex: "userToken",
      key: "userToken",
      ...columnSearchProps("userToken", "Usertoken"),
    },
    {
      title: "First name",
      dataIndex: "firstName",
      key: "firstName",
      ...columnSearchProps("firstName", "First name"),
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      key: "lastName",
      ...columnSearchProps("lastName", "Last name"),
    },
    {
      title: "Date of Birth",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      // ...columnSearchProps("dateOfBirth", "Date of Birth"),
      render: (value: any, record: AppUserListItem, index: number) => {
        return dayjs(value).format("YYYY-MM-DD");
      },
    },
    {
      title: "Phone number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      ...columnSearchProps("phoneNumber", "Phone number"),
    },
    {
      title: "Email",
      dataIndex: "emailAddress",
      key: "emailAddress",
      ...columnSearchProps("emailAddress", "Email"),
    },
  ];

  if (isError) {
    return <ErrorResult error={error} />;
  }
  return (
    <>
      <PageTitle text="Application users" />
      <Table
        columns={columns}
        dataSource={data}
        loading={isFetching}
        rowKey={(record) => record.userToken}
        pagination={{
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          onChange: paginationChanged,
        }}
        onRow={(record) => {
          return {
            onClick: (event) => {
              history.push(`/appUsers/${record.userToken}`);
            },
          };
        }}
      />
    </>
  );
};

export default AppUsers;
