import {
  CloseCircleOutlined,
  DeleteOutlined,
  LoadingOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Button, List, Popover, Tooltip } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import {
  useMarkAllAsRead,
  useMarkAsRead,
  useUnreadNotifications,
} from "./Layout.query";

interface Props {}

const PopoverContent = () => {
  const { data } = useUnreadNotifications();
  const { mutate } = useMarkAsRead();
  return (
    <List
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar icon={<NotificationOutlined />} />}
            title={<Link to={item.link || ""}>{item.category}</Link>}
            description={<Link to={item.link || ""}>{item.description}</Link>}
          />
          <Tooltip title="Mark as read" color="orange">
            <Button
              type="primary"
              danger
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => mutate(item._id)}
            />
          </Tooltip>
        </List.Item>
      )}
    />
  );
};

const Title = () => {
  const { mutate } = useMarkAllAsRead();
  return (
    <div className="flex justify-between">
      <span className="self-center">Notifications</span>
      <Button onClick={() => mutate()}>Mark all as read</Button>
    </div>
  );
};

const UserNotifications = (props: Props) => {
  const { data, isError, isLoading } = useUnreadNotifications();
  if (isLoading) {
    return <Avatar shape="circle" size="default" icon={<LoadingOutlined />} />;
  }
  if (isError) {
    return (
      <Avatar
        shape="circle"
        size="default"
        className="bg-red-500"
        icon={<CloseCircleOutlined />}
      />
    );
  }
  return (
    <Popover
      placement="bottomRight"
      title={<Title />}
      content={<PopoverContent {...props} />}
      trigger="click"
      overlayStyle={{ width: "25rem" }}
    >
      <Badge count={data!.length} overflowCount={10}>
        <Avatar shape="circle" size="default" icon={<NotificationOutlined />} />
      </Badge>
    </Popover>
  );
};

export default UserNotifications;
