import {
  BugOutlined,
  HomeOutlined,
  LockOutlined,
  MailOutlined,
  QuestionCircleOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "./logo_big.png";

interface Props {}

const MenuSider = (props: Props) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout.Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
      <img src={logo} alt="logo" className="p-3 mix-blend-screen" />
      <Menu defaultSelectedKeys={["1"]} mode="inline">
        <Menu.Item key="home" icon={<HomeOutlined />}>
          <Link to="/">Home</Link>
        </Menu.Item>
        <Menu.Item key="appUsers" icon={<TeamOutlined />}>
          <Link to="/appUsers">App users</Link>
        </Menu.Item>
        <Menu.SubMenu
          key="kyc"
          icon={<QuestionCircleOutlined />}
          title={<Link to="/kyc">KYC</Link>}
        >
          <Menu.Item key="pending">
            <Link to="/kyc?status=pending">Pending</Link>
          </Menu.Item>
          <Menu.Item key="approved">
            <Link to="/kyc?status=approved">Approved</Link>
          </Menu.Item>
          <Menu.Item key="suspended">
            <Link to="/kyc?status=suspended">Suspended</Link>
          </Menu.Item>
          <Menu.Item key="declined">
            <Link to="/kyc?status=declined">Declined</Link>
          </Menu.Item>
          <Menu.Item key="reinitiated">
            <Link to="/kyc?status=reinitiated">Reinitiated</Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item key="errors" icon={<BugOutlined />}>
          <Link to="/errors">Errors</Link>
        </Menu.Item>
        <Menu.Item key="invite" icon={<MailOutlined />}>
          <Link to="/invite">Invite</Link>
        </Menu.Item>
        <Menu.Item key="adminUsers" icon={<LockOutlined />}>
          <Link to="/adminUsers">Admin users</Link>
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  );
};

export default MenuSider;
