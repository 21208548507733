import { Address } from "apis/appUsers.api";
import { AgeGroup } from "apis/kyc.api";

export const buildURLQuery = (obj: Object) =>
  Object.entries(obj)
    .filter((pair) => pair[1] !== null && pair[1] !== undefined)
    .map((pair) => pair.map(encodeURIComponent).join("="))
    .join("&");

export const getDisplayAddress = (
  address: Address | undefined
): React.ReactNode => {
  return `${address?.countryCode} - ${address?.postalCode} ${address?.city}, ${
    address?.addressLine1
  } ${address?.addressLine2 ?? ""}`;
};

export const balanceFormat = (balanceValue: number) => {
  return balanceValue.toLocaleString("en-US");
};

export const ageGroupToLabel = (ageGroup: AgeGroup | undefined): string => {
  switch (ageGroup) {
    case "above18":
      return "Above 18";
    case "below14":
      return "Below 14";
    case "between14and18":
      return "Between 14 and 18";
    default:
      return "Unknown";
  }
};
