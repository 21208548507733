import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { createStateContext } from "react-use";
import { EmailCodeVerification } from "./EmailCodeVerification";
import { LoginForm } from "./LoginForm";

interface Props {}

const [useEmail, EmailProvider] = createStateContext("");

const Login = (props: Props) => {
  const [step, setStep] = useState<"login" | "verification">("login");
  const history = useHistory();
  const navigateToHomeScreen = () => history.push("/");

  return (
    <section className="min-h-screen flex items-stretch text-white">
      <div className="lg:flex w-1/2 hidden bg-gray-500 bg-no-repeat bg-cover relative items-center bg-login">
        <div className="absolute bg-black opacity-60 inset-0 z-0"></div>
        <div className="w-full px-24 z-10">
          <h1 className="text-5xl font-bold text-left tracking-wide">
            Visa Admin
          </h1>
        </div>
      </div>
      <div className="lg:w-1/2 w-full flex items-center justify-center text-center md:px-16 px-0 z-0 bg-ant-grey">
        <div className="absolute lg:hidden z-10 inset-0 bg-gray-500 bg-no-repeat bg-cover items-center bg-login">
          <div className="absolute bg-black opacity-60 inset-0 z-0"></div>
        </div>
        <div className="w-full py-6 z-20">
          <EmailProvider>
            <AnimatePresence exitBeforeEnter>
              {step === "login" ? (
                <LoginForm onSuccessfulLogin={() => setStep("verification")} />
              ) : (
                <EmailCodeVerification
                  onSuccessfulLogin={navigateToHomeScreen}
                />
              )}
            </AnimatePresence>
          </EmailProvider>
        </div>
      </div>
    </section>
  );
};

export { useEmail };
export default Login;
