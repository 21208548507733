import { buildURLQuery } from "utils/helpers";
import { axiosInstance } from "./axiosInstance";

export interface AppUsersQuery {
  page: number;
  pageSize: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  emailAddress?: string;
  dateOfBirth?: string;
}

export interface AppUserListItem {
  userToken: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phoneNumber: string;
  emailAddress: string;
}

export interface Address {
  countryCode: string;
  city: string;
  postalCode: string;
  addressLine1: string;
  addressLine2: string;
}

export interface AppUser {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phoneNumber: string;
  emailAddress: string;
  homeAddress: Address;
  postalAddress: Address;
  createdAt: string;
  updatedAt: string;
}

export interface AppUserRelations {
  isParent: boolean;
  children?: string[];
  parents?: string[];
}

export interface Card {
  cardToken: string;
  pspCardToken: string;
  pspPublicToken: string;
  active: boolean;
  issued: boolean;
  blocked: boolean;
}

export interface Balance {
  isParent: boolean;
  pspMainAccountBalance: number;
  pspSavingsBalance: number;
  savingsSubpocketsBalanceSum: number;
  savingsSubpockets: Subpocket[];
}

export interface Subpocket {
  subpocketToken: string;
  subpocketName: string;
  amount: number;
  goal: number;
  locked: boolean;
}

export interface Transaction {
  id: number;
  detailsRef: string;
  kind: string;
  stateId: number;
  stateName: string;
  eventDate: Date;
  trnAmount: number;
  trnCurrencyCode: string;
  fullName: string;
  accAmount: number;
}

export const getAppUsers = async (
  q: AppUsersQuery
): Promise<AppUserListItem[]> =>
  (await axiosInstance.get(`/admin/v1/appUsers?${buildURLQuery(q)}`)).data;

export const getAppUser = async (userToken: string): Promise<AppUser> =>
  (await axiosInstance.get(`/admin/v1/appUsers/${userToken}`)).data;

export const getAppUserCards = async (userToken: string): Promise<Card[]> =>
  (await axiosInstance.get(`/admin/v1/appUsers/${userToken}/cards`)).data;

export const getAppUserBalance = async (userToken: string): Promise<Balance> =>
  (await axiosInstance.get(`/admin/v1/appUsers/${userToken}/balance`)).data;

export const getAppUserRelations = async (
  userToken: string
): Promise<AppUserRelations> =>
  (await axiosInstance.get(`/admin/v1/appUsers/${userToken}/relations`)).data;

export const getAppUserTransactions = async (
  userToken: string
): Promise<Transaction[]> =>
  (await axiosInstance.get(`/admin/v1/appUsers/${userToken}/transactions`))
    .data;
