// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../node_modules/postcss-loader/src/index.js??postcss!../node_modules/antd/dist/antd.dark.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./img/waves.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* @import '~antd/dist/antd.css'; */\n\n.random-image-background::before {\n  content: \"\";\n  position: fixed;\n  left: 0;\n  right: 0;\n  z-index: -1;\n\n  display: block;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  width: 100%;\n  height: 100%;\n}\n", "",{"version":3,"sources":["webpack://src/App.css"],"names":[],"mappings":"AAAA,mCAAmC;;AAGnC;EACE,WAAW;EACX,eAAe;EACf,OAAO;EACP,QAAQ;EACR,WAAW;;EAEX,cAAc;EACd,yDAAwC;EACxC,sBAAsB;EACtB,WAAW;EACX,YAAY;AACd","sourcesContent":["/* @import '~antd/dist/antd.css'; */\n@import \"~antd/dist/antd.dark.css\";\n\n.random-image-background::before {\n  content: \"\";\n  position: fixed;\n  left: 0;\n  right: 0;\n  z-index: -1;\n\n  display: block;\n  background-image: url(\"./img/waves.svg\");\n  background-size: cover;\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
