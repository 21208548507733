import {
  AimOutlined,
  FundOutlined,
  LockTwoTone,
  PercentageOutlined,
  UnlockTwoTone,
} from "@ant-design/icons";
import { List } from "antd";
import { Balance } from "apis/appUsers.api";
import ServerStateDisplay from "components/shared/ServerStateDisplay";
import React from "react";
import { balanceFormat } from "utils/helpers";
import { useAppUserBalance } from "./AppUsers.query";
// import savings from "./savings.svg";

interface Props {
  data: Balance;
}

const AppUserSavings = ({ data }: Props) => {
  return (
    <div className="relative backdrop-brightness-200  hover:ring-1 rounded-xl p-4 w-full min-h-[8rem]">
      {/* <img
        className="absolute right-4 bottom-4 h-[5rem] rounded-xl -z-10 brightness-50"
        src={savings}
        alt="savings cover"
      /> */}
      <List
        itemLayout="horizontal"
        dataSource={data.savingsSubpockets}
        renderItem={(item) => (
          <List.Item key={item.subpocketToken}>
            <List.Item.Meta
              avatar={
                item.locked ? (
                  <LockTwoTone twoToneColor="red" />
                ) : (
                  <UnlockTwoTone twoToneColor="green" />
                )
              }
              title={
                <span className="font-semibold">{item.subpocketName}</span>
              }
              description={item.subpocketToken}
            />
            <div className="w-1/3">
              <div className="flex items-center gap-2">
                <AimOutlined />
                <span className="text-gray-600">$</span>
                <span className="font-semibold">
                  {balanceFormat(item.goal)}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <FundOutlined />
                <span className="text-gray-600">$</span>
                <span className="font-semibold">
                  {balanceFormat(item.amount)}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <PercentageOutlined />
                <span className="font-semibold">
                  {((item.amount / item.goal) * 100).toPrecision(4)}
                </span>
              </div>
            </div>
          </List.Item>
        )}
      />
    </div>
  );
};

export default ServerStateDisplay<Balance>(
  AppUserSavings,
  (userToken: string) => useAppUserBalance(userToken)
);
